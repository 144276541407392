<template>
  <v-row>
    <v-col cols="12">
      <PhotoList
        :customer="customer"
        :site_id="siteId"
        :photos="photos"
        :sites="sites"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import PhotoList from "@/components/layout/customer/PhotoList.vue";
export default {
  name: "Photos",
  components: { PhotoList },
  computed: {
    ...mapGetters(["photos"]),
    customer() {
      return this.$store.getters.getCustomer;
    },
    sites() {
      return this.customer.sites.map((sites, key) => {
        return { value: key, text: sites.name };
      });
    },
    siteId() {
      return parseInt(this.$route.params.site_id);
    }
  },
  created() {},
  data() {
    return {
      data: {
        site: 0
      }
    };
  }
};
</script>
