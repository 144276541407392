<template>
  <v-container class="photos-box">
    <v-dialog
      persistent
      ref="addPhotoDialog"
      v-model="addPhotoDialog"
      width="500"
    >
      <AddPhotoDialog
        :customer="customer"
        :site_id="site_id"
        @close-dialog="addPhotoDialog = false"
      ></AddPhotoDialog>
    </v-dialog>
    <v-dialog
      persistent
      ref="editPhotoDialog"
      v-model="editPhotoDialog"
      width="500"
    >
      <EditPhotoDialog
        :customer="customer"
        :site_id="site_id"
        :photo.sync="photo"
        @close-dialog="editPhotoDialog = false"
      ></EditPhotoDialog>
    </v-dialog>
    <v-dialog
      persistent
      ref="showPhotoDialog"
      v-model="showPhotoDialog"
      width="100%"
    >
      <ShowPhotoDialog
        :customer="customer"
        :site_id="site_id"
        :photo="photo"
        @close-dialog="showPhotoDialog = false"
      ></ShowPhotoDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="flex-grow-1">
        <v-row v-for="item in photos" :key="item.id" align="center">
          <v-col cols="6" class="photo-image">
            <v-img
              @click="showPhoto(item.id)"
              :src="item.path ? item.path_url : `${root}images/no-image.jpg`"
            ></v-img>
          </v-col>
          <v-col cols="6" @click="editPhoto(item.id)" class="photo-desc">
            <v-row>
              <v-col cols="12" class="grey--text text-caption pb-0">
                <span class="mr-3">{{ item.date }}</span>
                {{
                  item.updated_by_user ? item.updated_by_user.full_name : "-"
                }}
              </v-col>
              <v-col cols="12 text-heading-3 listing-para">
                {{ item.memo }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-center">
          <v-btn
            @click="addPhotoDialog = true"
            block
            x-large
            class="my-5 button-camera"
          >
            <v-icon class="mr-3">mdi-camera</v-icon>
            画像追加
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddPhotoDialog from "./photo/AddPhotoDialog";
import EditPhotoDialog from "./photo/EditPhotoDialog";
import ShowPhotoDialog from "./photo/ShowPhotoDialog";
export default {
  name: "BasicInformation",
  props: ["customer", "sites", "photos", "site_id"],
  components: { AddPhotoDialog, EditPhotoDialog, ShowPhotoDialog },
  data() {
    return {
      photo: {},
      addPhotoDialog: false,
      editPhotoDialog: false,
      showPhotoDialog: false,
      root: process.env.VUE_APP_ROOT_API
    };
  },
  methods: {
    editPhoto(id) {
      this.photo = this.photos.find(c => c.id == id);
      this.editPhotoDialog = true;
    },
    showPhoto(id) {
      this.photo = this.photos.find(c => c.id == id);
      this.showPhotoDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.photos-box {
  .photo-image {
    img {
      width: 100%;
      height: 120px;
      object-fit: cover;
    }
  }
  .button-camera {
    color: #828282;
  }
}
.listing-para{
  word-break: break-all;
}
</style>
